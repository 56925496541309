

const MainPage = () =>{
    return(
        <div className="mainPage">
            <div className="title">Головна сторінка</div>
            <div className="info">Вітаю на головній сторінці. 
            Цей додаток розроблений для вирішення щоденних питань операторів верстатів.</div>
        </div>
    )
}

export default MainPage