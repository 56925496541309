import st from './Weight.module.css'
import { useState } from 'react'

const Usage = (props) => {

    const [vothod, setVOthod] = useState()
    const [vdetali, setVdetali] = useState()
    const [proc, setProc] = useState()

    let calcv = () =>{
        console.log(proc);
        let a = props.result*(proc/100)
        setVdetali(a)
        console.log(vdetali);
        let b = props.result-vdetali
        setVOthod(b)
        console.log(vothod);

    }

    return (
        <div>
            <div className={st.title2}>Розрахунок використання</div>
            <div className={st.inptext}>
                <div>% викор-ння</div>
                <div><input type="number" onChange={event => setProc(event.target.value)}/></div>
                <div><button onClick={calcv}> calc</button></div>
            </div>
            <div className={st.text2}>Вага загальна: {props.result} кг</div>
            <div className={st.text2}>Вага деталей: {vdetali} кг</div>
            <div className={st.text2}>Вага залишків: {vothod} кг</div>
        </div>
    )
}

export default Usage